import * as React from 'react'
import { Link } from 'gatsby'

import Page from '../components/Page'
import Container from '../components/Container'
import IndexLayout from '../layouts'
import { css } from '@emotion/core'

const NotFoundPage = () => (
  <IndexLayout>
    <Page>
      <Container css={css`background-color: #fff; border-radius: 0.5rem; padding: 1rem;`}>
        <h1>404: Page not found</h1>
        <p>
          You've hit the void. <Link to="/">Go back.</Link>
        </p>
      </Container>
    </Page>
  </IndexLayout>
)

export default NotFoundPage
